
import {React, useState, useEffect} from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PopupComponentAdvertise from './pop/advertise';

const Header = ({ isFilterVisible, countChecked, manuelfilro}) => {
    const navigate = useNavigate();
    const {user, token} = UserAuth();
    const [isProUser, setisPro] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(true);
    //naviate to /account
    const account = async () => {
        navigate('/cuenta');
      }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PP}/api/paypal/subscription-status`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Make sure this is the correct token
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const userData = await response.json();
                if (userData && userData.isPro !== null) {
                    setisPro(userData.isPro);
                    setPopupVisible(!userData.isPro);
                    console.log("sus id: " + userData.susId);
                    console.log("is pro: " + userData.isPro);
                } else {
                    console.log("User data not found or user is not pro");
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        };
    
        fetchData();
    }, [user]);
    return (<>
        <div className="tooltip">
           
            
        <div className="content-section-title">
        <a href="#" style={{color: isProUser ? "rgba(0, 255, 0)" : "rgba(0, 255, 0)"}} onClick={account}> 
    {isProUser ? 'Usuario Pro' :<button 
    className='content-button status-button' > 
    ACTIVA TEMIS PRO AQUI
    </button>}
</a><br/>
          Consulta lo que necesites {user && user.email}:<br/> <br/>
          ¡Ayúdanos a mejorar Temis!<br/>
Queremos saber qué documentos y características te gustaría ver en nuestra plataforma. Tu feedback es esencial para ofrecerte el mejor servicio posible.

<br/>
<a href="https://forms.gle/1ajtPSG784ei4xPw9" style={{color: isProUser ? "rgba(0, 255, 0)" : "rgba(0, 255, 0)"}} target="_blank" rel="noreferrer">Feedback</a>
<br/>
          
          
          {isFilterVisible ? <p className="filter-status-link notify">
            Busqueda Filtrada Activada
            <span className="notification-number">{countChecked()}</span> 
             </p>: 'Busqueda Filtrada Desactivada'} 
             </div>
             </div>
             </>
    );
};

export default Header;
/*
<div className="tooltip-wrapper">
             <div className="tooltip-text"> <strong>Filtro de Documentos</strong>
    <br />
    <br />
    Acceda al filtro en el lado izquierdo o en el menú superior derecho. Active el switch y 
    seleccione los documentos específicos para una búsqueda precisa. Desactívelo para una búsqueda 
    general en todo el marco legal.
    <br />
    <br />
    Para más detalles, consulte el <button onClick={manuelfilro} className="content-button status-button open">Manual</button>
    </div></div>
    */
import { createUserWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { auth } from '../../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faGithub } from '@fortawesome/free-brands-svg-icons';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { createUser, signInWithGooglePopup } = UserAuth();
    const navigate = useNavigate();
    const [isVerified, setIsVerified] = useState(false);

    const onChange = (value) => {
        setIsVerified(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isVerified) {
            try {
                await createUser(email, password);
                navigate('/account');
            } catch (e) {
                let message;
                switch (e.code) {
                    case 'auth/email-already-in-use':
                        message = 'Este correo electrónico ya está en uso. Por favor, intenta con otro.';
                        break;
                    case 'auth/invalid-email':
                        message = 'Correo electrónico inválido. Por favor, ingresa un correo electrónico válido.';
                        break;
                    case 'auth/weak-password':
                        message = 'La contraseña es muy débil. Por favor, ingresa una contraseña más segura.';
                        break;
                    default:
                        message = 'Ocurrió un error. Por favor, intenta nuevamente.';
                }
                setError(message);
                setIsVerified(false);
            }
        } else {
            alert('Por favor verifica que no eres un robot.');
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGooglePopup();
            setIsVerified(true); // Set isVerified to true upon successful sign-in
            navigate('/account'); // Redirect user after sign-in
        } catch (error) {
            console.error(error);
            alert('No se pudo iniciar sesión con Google');
        }
    };

    return (
        <div className="app-card" style={{ alignItems: "center" }}>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="content-section-title">Crea una cuenta aquí</div>
                <input
                    className='search-bar'
                    type='email'
                    placeholder='Correo electrónico'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    className='search-bar'
                    type='password'
                    placeholder='Contraseña'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ReCAPTCHA sitekey="6LffcyMmAAAAAB8CKDyxexlZFUEmJAZ1VX-jbZaq" onChange={onChange} />
                </div>
                <button className="content-button status-button" type='submit'>Sign Up</button>
            </form>
            <div className="content-section"></div>
            <button className='content-button status-button open' style={{ width: "300px" }} onClick={handleGoogleSignIn}>
                <FontAwesomeIcon icon={faGoogle} /> continuar con Google
            </button>
            <div className="content-section-title">
                ¿Ya tienes una cuenta?<br />
                <Link to='/signin' className='link-button'>Ingresa Aquí</Link> a tu cuenta registrada
            </div>
            <div className="content-section-title">
                <Link to='/' className='link-button'>Regresar a la Página principal</Link>
            </div>
        </div>
    );
};

export default SignUp;

import React from 'react';
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom';

function Politica() {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    if (location.state?.from) {
      navigate(location.state.from, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    
    
    <div className="Politica">
        
     <div >
      <h1>AVISO LEGAL</h1>
      
     </div>
      <div >
        <div id="parrafo">
        <p>
                  
        Aviso legal<br/>

Por favor, lea atentamente este aviso legal antes de utilizar esta herramienta de asistente legal.<br/><br/>

El propósito de este asistente legal es proporcionar información general sobre leyes y reglamentaciones a los usuarios. Esta herramienta utiliza la inteligencia artificial para proporcionar respuestas a consultas legales.<br/><br/>

Sin embargo, tenga en cuenta que la información proporcionada por el asistente legal no constituye asesoramiento legal ni debe considerarse como tal. Aunque intentamos mantener la información lo más actualizada posible, no se garantiza la precisión, integridad, actualidad o adecuación de la información proporcionada por el asistente legal. La información proporcionada por el asistente legal puede no ser aplicable a su situación particular.<br/><br/>

El uso de esta herramienta es bajo su propio riesgo y responsabilidad. Al utilizar esta herramienta, usted acepta que los creadores y desarrolladores de esta herramienta no serán responsables de ninguna acción tomada o no tomada en base a la información proporcionada por el asistente legal. Además, los creadores y desarrolladores de esta herramienta no serán responsables de ningún daño, pérdida o perjuicio que resulte del uso de la información proporcionada.<br/><br/>

Si necesita asesoramiento legal específico o si tiene preguntas sobre la aplicabilidad de las leyes y reglamentaciones en su situación particular, le recomendamos que consulte a un abogado o profesional legal competente.<br/><br/>

Al utilizar esta herramienta, usted también acepta cumplir con todas las leyes y reglamentaciones aplicables en su jurisdicción.<br/><br/>

Nos reservamos el derecho de modificar este aviso legal en cualquier momento sin previo aviso. Es responsabilidad del usuario revisar este aviso legal periódicamente para mantenerse informado de los cambios.<br/><br/>

Si no está de acuerdo con alguno de los términos de este aviso legal, no utilice esta herramienta.</p></div>
      </div>

      <div className="content-section-title">POR FAVOR LEA ATENTAMENE NUESTRAS POLITICAS DE USO<br/> 
        <button onClick={goBack} className='link-button'>VOLVER</button> </div>
    </div>
  );
}

export default Politica;

import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PopupComponent from '../pop/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faGithub, faTwitter, faApple } from '@fortawesome/free-brands-svg-icons';

const Signin = () => {
    const { signIn, user, token, signInWithGooglePopup } = UserAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        if (user !== null && token !== '') {
            navigate('/account');
        }
    }, [user, token, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await signIn(email, password);
        } catch (e) {
            console.error(e.message);
            let message;
            switch (e.code) {
                case 'auth/user-not-found':
                    message = 'Usuario no encontrado. Por favor verifica tu email.';
                    break;
                case 'auth/wrong-password':
                    message = 'Contraseña incorrecta. Por favor intenta nuevamente.';
                    break;
                case 'auth/invalid-email':
                    message = 'Email inválido. Por favor ingresa un email válido.';
                    break;
                default:
                    message = 'Ocurrió un error. Por favor intenta nuevamente.';
            }
            setErrorMessage(message);
            setShowPopup(true);
        }
    };

    return (
        <div className="app-card" style={{ alignItems: "center" }}>
            {showPopup && (
                <PopupComponent 
                    message={errorMessage} 
                    onClose={handleClosePopup}
                />
            )}
            <form onSubmit={handleSubmit}>
                <div className="content-section-title">Si ya tiene una cuenta existente, ingresa aquí</div>
                <div className="content-section-title">Log In</div>
                <input
                    className='search-bar'
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    className='search-bar'
                    type='password'
                    placeholder='Contraseña'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className="content-button status-button" type='submit'>Log In</button>
            </form>
            <div className="content-section"></div>
            <button
                className='content-button status-button open'
                style={{ width: "300px" }}
                onClick={signInWithGooglePopup}
            >
                <FontAwesomeIcon icon={faGoogle} /> continuar con Google
            </button>
            <div className="content-section-title">
                ¿Aún no tienes una cuenta?<br />
                <Link to='/signup' className='link-button'>Regístrate</Link> con nosotros
            </div>
            <div className="content-section-title">
                <Link to='/' className='link-button'>Regresar a la Página principal</Link>
            </div>
            <div className="content-section-title">
                ¿Olvidaste tu contraseña? <Link to='/reset-password' className='link-button'>Reiníciala aquí</Link>
            </div>
        </div>
    );
};

export default Signin;

// PopupComponent.jsx
import React from 'react';

const PopupComponent = ({ message, onClose }) => {
    return (
        <div className="popup-container">
            <div className="popup">
                <p>{message}</p>
                <button className='content-button status-button open' onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default PopupComponent;

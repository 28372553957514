import React, { useState } from 'react';

const MarkupTextCopier = ({ originalMarkup }) => {
  const [copyStatus, setCopyStatus] = useState('');

  const simplifyMarkup = (markup) => {
    return markup
      .replace(/\*\*(.*?)\*\*/g, '$1')  // Remove bold formatting
      .replace(/\*(.*?)\*/g, '$1')      // Remove italic formatting
      .replace(/```(.*?)```/g, '$1')    // Remove code blocks but keep content
      .replace(/`(.*?)`/g, '$1')        // Remove inline code formatting
      .replace(/^\s*[-*+]\s/gm, '• ')   // Simplify list items
      .replace(/^\s*#{1,6}\s*/gm, '')   // Remove headers
      .replace(/\n{3,}/g, '\n\n')       // Replace multiple newlines with two
      .trim();                          // Trim leading and trailing whitespace
  };

  const copyToClipboard = () => {
    const simplifiedText = simplifyMarkup(originalMarkup);

    navigator.clipboard.writeText(simplifiedText)
      .then(() => {
        setCopyStatus('Copiado!');
        setTimeout(() => setCopyStatus(''), 2000);
      })
      .catch(err => {
        console.error('Error copying text: ', err);
        setCopyStatus('Failed to copy');
      });
  };

  return (
    <div>
      <button className='content-button status-button' onClick={copyToClipboard}>Copiar</button>
      {copyStatus && <span>{copyStatus}</span>}
    </div>
  );
};

export default MarkupTextCopier;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeQC0HRn7xMaiTY21ve1-oFNXdSrmj8ew",
  authDomain: "test-auth-25f49.firebaseapp.com",
  projectId: "test-auth-25f49",
  storageBucket: "test-auth-25f49.appspot.com",
  messagingSenderId: "941943557001",
  appId: "1:941943557001:web:e281f49ea1d82ca918ef58",
  measurementId: "G-Q4RBKF7QMY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export default app;
export const auth = getAuth(app);
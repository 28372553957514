import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";

function CancelSubscription({setispro}) {
    
    const [message, setMessage] = useState("");
    const { user} = UserAuth();
    const [susId, setSusId] = useState("");
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const token = getCookie('token');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PP}/api/paypal/subscription-status`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Make sure this is the correct token
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const userData = await response.json();
                if (userData && userData.isPro !== null) {
                    setSusId(userData.susId);
                    console.log("sus id: " + userData.susId);
                    console.log("is pro: " + userData.isPro);
                } else {
                    console.log("User data not found or user is not pro");
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        };
    
        fetchData();
    }, [user]);

    const handleCancelSubscription = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PP}/api/paypal/cancel-subscription`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ subscriptionId: susId }),
            });
            const data = await response.json();
            if (response.ok) {
                setMessage("Subscription cancelled successfully.");
                setispro(false);
            } else {
                setMessage("Error: " + data.error);
            }
        } catch (error) {
            setMessage("Failed to cancel subscription. Error: " + error.message);
        }
    };

    return (
        <div className="CancelSubscription">
            <h3>Cancel Subscription</h3>
            <button className="content-button status-button open" onClick={handleCancelSubscription}>Cancel Subscription</button>
            {message && <p>{message}</p>}
        </div>
    );
}

export default CancelSubscription;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PopupComponent from '../pop/popup';

const FeedbackForm = () => {
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isNotificationPopupVisible, setIsNotificationPopupVisible] = useState(false);
    const [msg, setmsg] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        const token = getCookie('token'); // Using your getCookie function
        
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PP}/api/submit-feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ feedback })
        });

        const data = await response.json();
        //if response status 200
        
        //if 500 its an error
        
            
            //alert(data.message);
            setmsg(data.message);
            setIsNotificationPopupVisible(true);
        


        setIsLoading(false);

        // Handle response...
        console.log(data);
    };

    return (
        <>
            {isNotificationPopupVisible ? (
        <PopupComponent
        message={msg}
        onClose={() => setIsNotificationPopupVisible(false)}
      />
      )
      :
        <form onSubmit={handleSubmit}>
            
            <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Your feedback..."
                required
            />
            <button className='content-button status-button open' type="submit" disabled={isLoading}>
                {isLoading ? 'Sending...' : 'Send Feedback'}
            </button>
        </form>
        }
        </>
    );
};

export default FeedbackForm;

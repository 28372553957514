import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';
// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

function Month({setispro}) {
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    //const { user, token } = UserAuth(); // Or however you get the user object
    const token = getCookie('token');
    const navigate = useNavigate();
    const Themis = async () => {
        navigate('/account');
      }
  const initialOptions = {
    "client-id": `${process.env.REACT_APP_API_CLIENT_ID_PP}`,
    "enable-funding": "card",
    "disable-funding": "paylater",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };

  const [message, setMessage] = useState("");
  const verifySubscription = async (subscriptionId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PP}/api/paypal/verify-subscription`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ subscriptionId }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error verifying subscription:", error);
        setMessage(`Error verifying subscription: ${error.message}`);
    }
};


  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createSubscription={async () => {
            try {
                //`${baseURL}/api/query`
                //console.log("token: " + token);
              const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PP}/api/paypal/create-subscription`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userAction: "SUBSCRIBE_NOW" }),
              });
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const data = await response.json();
              if (data?.id) {
                setMessage(`Successful subscription...`);
                
                return data.id;
              } else {
                console.error(
                  { callback: "createSubscription", serverResponse: data },
                  JSON.stringify(data, null, 2),
                );
                // (Optional) The following hides the button container and shows a message about why checkout can't be initiated
                const errorDetail = data?.details?.[0];
                setMessage(
                  `Could not initiate PayPal Subscription, refresh the page or login again if the problem continues...<br><br>${
                    errorDetail?.issue || ""
                  } ${errorDetail?.description || data?.message || ""} ` +
                    (data?.debug_id ? `(${data.debug_id})` : ""),
                  { hideButtons: true },
                );
              }
            } catch (error) {
              console.error(error);
              if (error.message.includes("Token authorization failed")) {
                // Prompt user to re-login or refresh session
                setMessage("Session expired. Please log in again.");
                // Redirect to login page or show login modal
              } else {
                setMessage(`Could not initiate PayPal Subscription...${error}`);
              }
            }
          }}
          onApprove={async (data, actions) => {
            if (data.orderID) {
                const verificationResult = await verifySubscription(data.subscriptionID);
                if (verificationResult && verificationResult.message === "Subscription verified and user updated.") {
                    setMessage(`You have successfully subscribed to the plan. Your subscription id is: ${data.subscriptionID}`);
                    setispro(true);
                    Themis();
                } else {
                    setMessage(`Failed to activate the subscription: ${data.subscriptionID}`);
                }
            } else {
                setMessage(`Failed to activate the subscription: ${data.subscriptionID}`);
            }
        }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

export default Month;

import React from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'


const ProtectedRoute = ({children}) => {
const {user} = UserAuth();
if(!user)
{ console.log("palacasa"); 
    return <Navigate to="/signin" />}

  return children;
}

export default ProtectedRoute
import React from 'react';
import imagen from '../images/temis1.png';
import imagen2 from '../images/temis2.png';
import imagen3 from '../images/temis3.png';
import imagen4 from '../images/law22.png';
import telefono from '../images/4crop2.png';
import chavo from '../images/1crop.png';
import senora from '../images/3crop.png';
import telefono_frente from '../images/5.png';
import chocochavo from '../images/chocochavo.png';
import n1 from '../images/n1.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

const LandingPage = () => {
    const navigate = useNavigate();
    const politica = async () => {
        navigate('/politica');
      }
      //const login go to /login
        const login = async () => {
            navigate('/signin');
            }
            //const signup go to signup
            const signup = async () => {
                navigate('/signup');
                }
            //const manuel to /manual
            const manuel = async () => {
                navigate('/manual');
                }

    return (
        
            <div className="wrapper">
                <div className="left-side">
                <div className="side-wrapper">
                <div className="side-menu">
                                <button onClick={signup} className="content-button status-button open">Registrarse</button>
                                <button onClick={login} className="content-button status-button open">Login</button>
                                <button onClick={manuel} className="content-button status-button open">Manual</button>
                                
                            </div>
                            </div>
                    </div>
                
                <div className="main-container">   
                    
                    <div className="content-wrapper">
                        
                        <div className='content-wrapper-header'>
                            
                            <div className="content-wrapper-context">
                                <h2>Temis, Inteligencia Artificial aplicada a la Ley</h2>
                            </div>
                            <img className='content-wrapper-img3' src={imagen4} alt="Example question 1 and answer" />
                        </div>
                        <div className=''>
                            <div className="side-wrapper">
                                <div className='content-section'></div>
                                <button onClick={login} className="content-button"><h2>Comenzar</h2></button>
                                
                            </div>
                        </div>
                        <div >
                            <h2>¿Qué es Temis?
                            
                            </h2>
                            <div className="apps-card">
                                <div className="app-card">
                                    <span> Asistencia Legal Inteligente</span>
                                    
                                    <div className="app-card__subtext">
                                    Resuelve tus consultas legales al instante. Rápido, 
                                    preciso y basado en la ley guatemalteca. 
                                    Tu asistente legal digital, ¡siempre a tu servicio!
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className='content-section' >
                           
                        </div>
                        <div className='content-section'></div>
                        <div className='content-wrapper-header'>
                            <img className='content-wrapper-img'  src={telefono}   alt="Example question 1 and answer" />
                            <div className="content-wrapper-context">
                                <h2>Haga cualquier pregunta legal y obtenga respuestas instantáneas y precisas</h2>
                            </div>
                        </div>
                        <div>
                            <h2>¿Por qué Temis?
                            
                            </h2>
                            <div className="apps-card" style={{"alignItems":"center"}}>
                                
                                <div className="app-card2">
                                    <span>Inmediato y Certero</span>
                                    <div className="app-card__subtext2">Sin la necesidad de navegar horas por textos legales, Temis ofrece soluciones claras y certeras al instante.</div>
                                </div>
                                <div className="app-card2">
                                    <span>Acceso Automatizado al Derecho</span>
                                    <div className="app-card__subtext2">Temis redefine la consulta legal, simplificando el proceso con respuestas intuitivas automatizadas.</div>
                                </div>
                            </div>
                        </div>
                        <div className='content-section'></div>
                        <div className='content-wrapper-header'  style={{"padding": "0px 40px"}}>
                            <div className="content-wrapper-context">
                                <h2>Temis le permite enfocarse en lo esencial optimizando su tiempo al máximo</h2>
                            </div>
                            <img className='content-wrapper-img' src={chavo} alt="Example question 1 and answer" />
                        </div>
                        <div className='content-section'></div>
                        <div className='content-wrapper-header' >
                            <img className='content-wrapper-img' src={telefono_frente} alt="Example question 1 and answer" />
                            <div className="content-wrapper-context">
                                <h2>Explore la ley de una manera amigable e intuitiva</h2>
                            </div>
                        </div>
                        <div className='content-section'></div>
                        <div className='content-wrapper-header'  style={{"padding": "0px 40px"}}>
                            <div className="content-wrapper-context">
                                <h2>Obtenga información legal sin tener que bucear en textos legales</h2>
                            </div>
                            <img className='content-wrapper-img' src={senora} alt="Example question 1 and answer" />
                        </div>
                        
                        <div className='content-section'></div>
                        <div >  
                            <h2>Temis</h2>
                            <img className='logo-wrapper' src={imagen4} alt="Logo" />
                        </div> 
                        <div className=''>
                            <div className="side-wrapper">
                                <h2>¿Listo para comenzar?</h2>
                                
                                <button onClick={login} className="content-button"><h2>Comenzar</h2></button>
                                
                            </div>
                        </div>
                        <div className='content-section'>
                        <div className="side-wrapper">
                            <button onClick={politica} className="content-button status-button open">Lea la política de uso aquí</button>
                            
                            </div>
                            </div>
                        <div className='content-section'>
                        <div className="side-wrapper">
                        <button onClick={manuel} className="content-button status-button open">Lea el Manual de Usuario aquí</button>
                        </div>
                        </div>
                        <div className='content-section'></div>
                        <ul className="social-media-list">
    <li onClick={() => window.open("https://twitter.com/temisgpt", "_blank")}>
        <a href="https://twitter.com/temisgpt" target="_blank" rel="noopener noreferrer" className="contact-icon">
            <FontAwesomeIcon icon={faTwitter} />
        </a>
    </li>
    <li onClick={() => window.open("https://www.instagram.com/temisgpt/", "_blank")}>
        <a href="https://www.instagram.com/temisgpt/" target="_blank" rel="noopener noreferrer" className="contact-icon">
            <FontAwesomeIcon icon={faInstagram} />
        </a>
    </li>
</ul>


        <div className='content-section'></div>
        <a href="https://twitter.com/temisgpt" target="_blank" rel="noopener noreferrer" className="contact-icon"> </a>
         



                        
                    </div>
                    
                </div>
                
            </div>
            
        
    );
};

export default LandingPage;

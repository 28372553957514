import React from 'react';
import imagen from '../images/temis1.png';
import imagen2 from '../images/temis2.png';
import imagen3 from '../images/temis3.png';
import imagen4 from '../images/law22.png';
import manuel1 from '../images/manuel1.png'
import manuel2 from '../images/manuel2.png'
import manuel3 from '../images/manuel3.png'
import manuel4 from '../images/manuel4.png'
import manuel5 from '../images/manuel5.png'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Manuel = () => {
    const navigate = useNavigate();
    const politica = async () => {
        navigate('/politica');
      }
      //const login go to /login
        const login = async () => {
            navigate('/signin');
            }
            //const signup go to signup
            const signup = async () => {
                navigate('/signup');
                }

    return (
        
            
                
                
                <div className="main-container">   
                    
                    <div className="content-wrapper">
                        <div >  
                            <h2>Manual de Usuario Temis</h2>
                            <img className='logo-wrapper' src={imagen4} alt="Logo" />
                        </div> 
                        <div >
                            <div className="content-section-title">
                            <p style={{ textAlign: "left" }}>
                            Bienvenido al Manual del Usuario de Temis, diseñado para ayudarle a comprender y emplear de manera eficiente esta innovadora herramienta. Este manual se concentra en la correcta utilización y las mejores prácticas para optimizar el uso de Temis, a fin de que pueda aprovechar al máximo su funcionalidad.<br/><br/>
Temis, una Inteligencia Artificial avanzada, se desempeña como su asistente personal en el ámbito del derecho guatemalteco. Aunque todavía estamos trabajando para incorporar el 100% del marco legal del país, Temis se actualiza continuamente para expandir su cobertura.<br/><br/>
Para interactuar con Temis, usted simplemente necesita formular una pregunta. La herramienta entonces buscará a través de la vasta base de datos legal, seleccionando los artículos y disposiciones que mejor se alineen con su consulta. Posteriormente, le proporcionará una respuesta estructurada y clara, destinada a servir como un punto de partida para su estudio y exploración del marco legal en cuestión.<br/><br/>
Es importante destacar que Temis no tiene la intención de reemplazar métodos de estudio tradicionales o el trabajo de profesionales del derecho. Asimismo, no se concibe como una fuente de recomendaciones legales. Su objetivo principal es facilitar el acceso al marco legal guatemalteco, funcionando como un eficiente guía de estudio y exploración, y una herramienta útil para la expansión de su comprensión y conocimiento legal.<br/><br/>

                                </p>
                                
                            </div>
                            <div >  
                            <h2 id= "Interfaz">Interfaz</h2>
                            <div className="content-section-title">
                            <p style={{ textAlign: "left" }}>
                            La interfaz de Temis es intuitiva y se adapta automáticamente al dispositivo que esté utilizando. Con el fin de proporcionar instrucciones precisas y útiles.<br/><br/>
Una vez que inicie sesión en su cuenta de Temis, se encontrará con la única y sencilla interfaz de trabajo que hemos diseñado para su comodidad. Esta interfaz consta de tres componentes principales:

                                </p></div>
                        </div> 

                            <div className="apps-card">
                                <div className="app-card2">
                                    <span>Espacio de texto</span>
                                    <div className="app-card__subtext2">Este es el lugar donde podrá redactar y formular sus consultas.</div>
                                </div>
                                <div className="app-card2">
                                    <span>Botón de generar</span>
                                    <div className="app-card__subtext2">Una vez redactada su pregunta, haga clic en este botón para que Temis procese su consulta y genere una respuesta basada en el marco legal guatemalteco.</div>
                                </div>
                                <div className="app-card2">
                                    <span>Interruptor de opciones</span>
                                    <div className="app-card__subtext2">Este componente le permitirá ajustar ciertas preferencias y personalizar su experiencia de usuario.</div>
                                </div>
                            </div>
                            <div className="content-section-title">
                            <p style={{ textAlign: "left" }}>
                            Cada componente de la interfaz ha sido diseñado con el objetivo de que su interacción con Temis sea sencilla, eficaz y satisfactoria.
                            
                                </p><img className='content-wrapper-img' src={manuel1} alt="Example question 1 and answer" />
                                
                                <p style={{ textAlign: "left" }}>
                                Después de introducir su consulta en el espacio de texto, pulse el botón 'Generar' para que Temis procese su pregunta. Dado que Temis es una inteligencia artificial, tiene la capacidad de generar múltiples respuestas a partir de una misma pregunta.<br/><br/>
Por lo tanto, si la primera respuesta que obtiene no satisface completamente sus necesidades o expectativas, no dude en hacer clic nuevamente en 'Generar'. Esto permitirá a Temis presentar una respuesta alternativa a su consulta. Recuerde, la diversidad de respuestas es una característica clave de Temis, diseñada para proporcionarle diferentes enfoques y perspectivas sobre la misma cuestión legal.

                                </p>
                                
                                
                                
                                </div>
                        </div>
                        <div className='content-section' >
                            <h2 id= "Filtros">Filtros</h2>
                            <div className="content-section-title">
                            <p style={{ textAlign: "left" }}>
                            Ubicado en la parte izquierda de la pantalla, encontrará un interruptor o 'switch' que activa la funcionalidad de filtrado por documentos. Este control le permite personalizar los resultados de su búsqueda, ajustándolos según sus necesidades o intereses específicos. Al activarlo, Temis refinará sus respuestas tomando en cuenta únicamente los documentos o tipos de documentos que usted haya seleccionado previamente. Esto resulta especialmente útil cuando desea concentrar su búsqueda en una categoría particular de documentos legales.
                            </p>
                            
                            <img className='content-wrapper-img4' src={manuel2} alt="Example question 1 and answer" />
                            <p style={{ textAlign: "left" }}>
                            Mientras la función de filtrado esté desactivada, Temis realizará una búsqueda exhaustiva en todo el marco legal, seleccionando los artículos más relevantes para su consulta y formulando una respuesta en consecuencia. Sin embargo, dado que Temis opera con un contexto limitado, mantener el filtro desactivado podría resultar en una respuesta más generalizada. Esto se debe a que el contexto de búsqueda se extiende a todos los documentos legales disponibles, lo cual no es necesariamente malo, pero si está buscando información más específica, se recomienda utilizar la función de filtrado.<br/><br/>
                                Si desea obtener información detallada de ciertos documentos, o interactuar con la información específica de un documento o conjunto de documentos particulares, se recomienda activar el filtro de documentos. Esta acción hará que el contexto de búsqueda de Temis se centre en el documento o los documentos seleccionados, resultando en una búsqueda más enfocada y meticulosa en el tema o temas de interés. Al activar la función de filtrado, se desplegarán los documentos disponibles para que seleccione aquellos que sean más pertinentes para su consulta.

                                </p>
                                <img className='content-wrapper-img4' src={manuel3} alt="Example question 1 and answer" />
                                <p style={{ textAlign: "left" }}>
                                Actualmente, la base de datos de Temis comprende exclusivamente los documentos mostrados en la opción de filtro de documentos. Aunque nos encontramos en un proceso constante de esfuerzo para añadir y mantener actualizados más documentos, estos son los únicos disponibles por el momento.<br/><br/>
Al seleccionar los documentos deseados, Temis focalizará su búsqueda exclusivamente dentro de esos textos seleccionados, proporcionando respuestas y enfoques que están directamente relacionados con esos documentos.<br/><br/>
Por favor, tenga en cuenta que independientemente de si el filtro está activado o no, Temis solo puede buscar y extraer información de los documentos actualmente disponibles en su base de datos. No obstante, estamos comprometidos con la ampliación continua de esta base de datos para proporcionar un recurso más amplio y versátil para sus necesidades legales. Apreciamos su comprensión y paciencia mientras trabajamos en esta expansión.<br/><br/>
                                </p>
                                </div>

                                <h2 id="Recomendaciones">RECOMENDACIONES DE BUSQUEDA</h2>
                            <ul> 
                                <li className="adobe-product">
                                    <div className="products">
                                    Evite preguntar directamente por el número de artículo<br/> 
                                    </div>
                                    </li>
                                    </ul> 
                                    <div className="content-section-title">
                            <p style={{ textAlign: "left" }}>
                            Temis funciona como un buscador temático; interpreta su consulta, identifica el tema central y luego 
                            compara este tema con el contenido de todo el marco legal para encontrar los artículos relacionados. Por 
                            lo tanto, preguntar directamente por el contenido de un número de artículo específico probablemente no le 
                            proporcionará una respuesta útil. Por ejemplo:
                            </p>
                            <img className='content-wrapper-img' src={manuel4} alt="Example question 1 and answer" />
                            <p style={{ textAlign: "left" }}>
                            Por el contrario, una consulta efectiva se centrará en el tema legal en cuestión, en lugar de referirse a números de artículos específicos. Por ejemplo: 
                            </p>
                            <img className='content-wrapper-img' src={manuel5} alt="Example question 1 and answer" />
                            </div>
                            <ul> 
                                <li className="adobe-product">
                                    <div className="products">
                                    Evite usar la palabra "Guatemala" <br/> 
                                    </div>
                                    </li>
                                    </ul> 
                                    <div className="content-section-title">
                            <p style={{ textAlign: "left" }}>
                            Dado que Temis está diseñado específicamente para el contexto legal guatemalteco, la inclusión de la palabra "Guatemala" en su consulta podría resultar redundante y podría llevar a Temis a generar respuestas demasiado generalizadas.
                            </p>
                            
                            </div>


                            <ul> 
                                <li className="adobe-product">
                                    <div className="products">
                                    Genere múltiples respuestas<br/> 
                                    </div>
                                    </li>
                                    </ul> 
                                    <div className="content-section-title">
                            <p style={{ textAlign: "left" }}>
                            Como Temis es una Inteligencia Artificial, puede proporcionar diferentes respuestas a una misma consulta. Por lo tanto, si no está satisfecho con la respuesta inicial de Temis, le recomendamos que genere varias respuestas hasta encontrar la que le resulte más útil.
                            </p>
                            <p style={{ textAlign: "left" }}>
                            Además, le instamos a leer detenidamente nuestra política de uso para obtener una orientación más detallada sobre las respuestas proporcionadas por Temis. Nuestro objetivo es ayudarle a hacer un uso efectivo y responsable de nuestra herramienta, al tiempo que aseguramos su comprensión completa de sus funciones y limitaciones.
                            </p>
                            </div>

                            
                                         
                            
                        </div>
                        <div className='content-section'></div>
                        <div className='content-wrapper-header'>
                        
En nombre de todo el equipo de Temis, queremos agradecerle por su interés y confianza en nuestra herramienta. Como una startup emergente, estamos dedicados a mejorar continuamente y a expandir nuestras capacidades para proporcionarle una experiencia más rica y eficiente en su exploración del marco legal guatemalteco.<br/><br/>

Reconocemos que el camino hacia la comprensión y la navegación por la ley puede ser complicado y, a veces, desalentador. Con Temis, nuestra meta es hacer que este camino sea un poco más sencillo y accesible para todos, independientemente de su nivel de experiencia o conocimiento legal.<br/><br/>
Estamos en constante evolución, aprendiendo y creciendo junto con nuestros usuarios. Valoramos enormemente sus comentarios y sugerencias, ya que nos ayudan a comprender mejor sus necesidades y a hacer de Temis una herramienta aún mejor. Así que por favor, no dude en compartir sus pensamientos y experiencias con nosotros.<br/><br/>
De todo corazón, gracias.
                            
                            
                               
                            
                        </div>
                        
                        
                        <div className='content-section'></div>
                        <div className='content-wrapper-header'>
                            
                            <div className="content-wrapper-context">
                                <h2>Temis, Inteligencia Artificial aplicada a la Ley</h2>
                            </div>
                            <img className='content-wrapper-img3' src={imagen4} alt="Example question 1 and answer" />
                        </div>
                        <div className=''>
                            <div className="side-wrapper">
                                <h2>¿Listo para comenzar?</h2>
                                
                                <button onClick={login} className="content-button status-button open">Comenzar</button>
                                
                            </div>
                        </div>
                        <div className='content-section'>
                        <div className="side-wrapper">
                            <button onClick={politica} className="content-button status-button open">Lea la política de uso aquí</button>
                            </div>
                            </div>
                        <div className='content-section'></div>
                        <ul className="social-media-list">
    <li onClick={() => window.open("https://twitter.com/temisgpt", "_blank")}>
        <a href="https://twitter.com/temisgpt" target="_blank" rel="noopener noreferrer" className="contact-icon">
            <FontAwesomeIcon icon={faTwitter} />
        </a>
    </li>
    <li onClick={() => window.open("https://www.instagram.com/temisgpt/", "_blank")}>
        <a href="https://www.instagram.com/temisgpt/" target="_blank" rel="noopener noreferrer" className="contact-icon">
            <FontAwesomeIcon icon={faInstagram} />
        </a>
    </li>
</ul>

        <div className='content-section'></div>
                    </div>
                    
                </div>
                
            
            
        
    );
};

export default Manuel;

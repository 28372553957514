import { createContext, useContext, useEffect, useState } from "react";
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged,
    sendPasswordResetEmail,
} from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { auth } from "../firebase";



const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const[user, setUser] = useState({});
    const [token, setToken] = useState('');
    const[bandera, setBandera] = useState('');
    // Function for logging usage data
    
    const googleProvider = new GoogleAuthProvider();
// Sign in with popup
const signInWithGooglePopup = () => {
    return signInWithPopup(auth, googleProvider).then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // The signed-in user info.
        const user = result.user;
        
        // You can set the user and token state here
        setUser(user);
        user.getIdToken().then((token) => {
            setToken(token);
        });
    }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // TODO: handle errors e.g., by showing an error message
        console.error(errorMessage);
    });
};







    const createUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
            // Signed in 
            let user = userCredential.user;
            user.getIdToken().then((token) => {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/api/revokeTokens`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'Successfully revoked tokens') {
                        console.log('Tokens revoked successfully');
                        //signInWithEmailAndPassword(auth, email, password);
                        //setBandera(Math.random() * 10000);
                        //wait 2 seconds disable the mouse and keyboard 
                        //setTimeout(() => {
                        //    setBandera(Math.random() * 10000);
                        //}, 100);



                        
                        signOut(auth);
                        signInWithEmailAndPassword(auth, email, password);
                        //
                        //user.getIdToken(true).then((newToken) => {
                        //    setToken(newToken);
                        //});
                        
                        
                    } else {
                        console.log('Failed to revoke tokens:', data.error);
                        setToken('');
                        return signOut(auth);
                    }
                });
            });
        })
        .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            return Promise.reject(error);
        });
        
    }
    


    const logout = () => {
        setToken('');
        return signOut(auth);

    }



    const fetchNotifications = async () => {
        //to do later return anything but odnt make fetches
        return true;
    };
    
    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    };
    

    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    
    //if current user is not null 
    //then get the token
    //and set the user and token state
    //else set the user state to null
    if (currentUser)
    {
            currentUser.getIdToken().then(tK => {  
                setUser(currentUser);      
                setToken(tK);
                document.cookie = `token=${tK};path=/;max-age=3600;Secure;SameSite=Lax`;
                console.log("aut correcto");
            });
        }
        else
        {
            setUser(null);
            setToken('');
            console.log("aut incorrecto");
        }
        
        
    });
      
        return() => { unsubscribe(); }; 
      }, [bandera]);





    return (<UserContext.Provider value={{createUser, user, logout, signIn, token,fetchNotifications, resetPassword,signInWithGooglePopup}}>
        {children}
        </UserContext.Provider>);
}
export const UserAuth  = () => {
    return useContext(UserContext);
}
import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Month from '../checkout/month';
import CancelSubscription from '../checkout/cancel';
import FeedbackForm from './feedback/feedback';
import ProComparisonTable from './ProComparisonTable';

const Account = () => {
  const Felicidades = "Felicidades, eres usuario Premium de TemisGPT. Ahora tienes acceso exclusivo a nuestra Inteligencia Artificial más avanzada, con mucho mayor capacidad de inteligencia y análisis. Este nivel superior de servicio te garantiza respuestas más precisas y detalladas. Con la capacidad de buscar en un contexto más amplio y analizar más de tres veces la cantidad de artículos que el algoritmo estándar, nuestro sistema Pro está diseñado para ofrecerte información más específica y exacta, además de acceso al Diario Oficial por fechas. Disfruta de la experiencia Premium y maximiza tu productividad con respuestas de alta calidad adaptadas a tus necesidades.";
  const Pruebalo = "¿Listo para llevar tus búsquedas de información al siguiente nivel? Prueba nuestro plan Pro y accede a una inteligencia artificial avanzada. Con el plan Pro, disfrutarás de un contexto de búsqueda hasta 6 veces más amplio y un análisis mucho más detallado. Además, tendrás acceso exclusivo a todas las actualizaciones y al Diario Oficial de Centroamérica. Hemos comenzado a recopilar las publicaciones del Diario Oficial a partir del 29 de mayo de 2024, y diariamente agregamos todas las publicaciones para que sean accesibles por fechas. ¡Suscríbete ya y transforma la forma en que obtienes información! Tu apoyo nos permite seguir creciendo e innovando para brindar un mejor servicio.";
  const { user, logout} = UserAuth();
  const[tokenn, settoken] = useState("");
    const navigate = useNavigate();
    const [estatus, setEstatus] = useState("");
    const[isPro, setisPro] = useState(false);
    //const [colordeestado, setcolor] = useState("");
    const [suscriptionId, setSuscriptionId] = useState("");
    //useffect to generate new tokenid when loading component like this user.getIdToken().then(tK => {
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    useEffect(() => {
      //console.log("user: " + user);
      //if user is not nutt then proceed 
        if (user) {
        settoken(getCookie('token'));
        }
    }, [user]);
 
  

    const handleLogout = async () => {
        try
          {
              await logout();
              navigate('/signin');
  
          }
        catch (e) {
          alert(e.message);
        }
      }
      const politica = async () => {
        navigate('/account/politica');
      }
      const Themis = async () => {
        navigate('/account');
      }
      //useffect to retrieve susId and pro status and print it on the screen
      useEffect(() => {
        setSuscriptionId("");
        const fetchData = async () => {
            try {
              if (tokenn) {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PP}/api/paypal/subscription-status`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${tokenn}`, // Make sure this is the correct token
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const userData = await response.json();
                if (userData && userData.isPro !== null) {
                    setisPro(userData.isPro);
                    setEstatus(userData.Status);
                    setSuscriptionId(userData.susId);

                    console.log("sus id: " + userData.susId);
                    console.log("is pro: " + userData.isPro);
                } else {
                    console.log("User data not found or user is not pro");
                }

              }
            } 
            catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        };
    
        fetchData();
    }, [tokenn]);

    //useffect to retrieve color from the setEstatus, ony ACTIVE, SUSPENDED, AND CANCELLED
    /*useEffect(() => {
      if(estatus === "ACTIVE"){
        setcolor("rgba(0, 255, 0)");
      }
      if(estatus === "SUSPENDED"){
        setcolor("yellow");
      }
      if(estatus === "CANCELLED"){
        setcolor("red");
      }
    }, [estatus]);*/
    const colordeestado = estatus === "ACTIVE" ? "rgba(0, 255, 0)" : estatus === "SUSPENDED" ? "yellow" : estatus === "CANCELLED" ? "red" : "";
    
      
     
  return (
    <div className='wrapper'>
        <div className='left-side'>
        <div className='side-wrapper'>
          <div className='side-menu'>
          <button onClick={handleLogout} className="content-button status-button open" type="submit" >logout</button>
          <button onClick={Themis} className="content-button status-button open" type="submit" >Temis</button>
          <button onClick={politica} className="content-button status-button open" type="submit" >Politica</button>
          
          </div>
          </div>
          </div> 
          <div className="content-section">
            <div className="content-section-title"> ¡Hola! {user && user.email} </div>
                <div className='apps-card'>
                
                <div className='content-section'>
                  {isPro || estatus==="SUSPENDED"  ? (<p style={{ maxWidth: "95%" }}>{Felicidades}</p>): <p style={{ maxWidth: "95%" }}>{Pruebalo}</p>}
                   </div>
                   <div className='content-section'>
                <ProComparisonTable />
                </div>
                   <div className='content-section'>
                <span> Solamente por: </span> <div className="app-card2__subtext"><strong>$8.96 al mes</strong></div></div>
                   <div className="app-card2"> 
                <span>Fecha de union</span> <div className="app-card2__subtext"> {user && user.metadata && new Date(user.metadata.creationTime).toLocaleString("es-ES", {timeZone: "America/Guatemala"})}</div> 
                <span> Fecha de ultima conexion</span> <div className="app-card2__subtext"> {user && user.metadata && new Date(user.metadata.lastSignInTime).toLocaleString("es-ES", {timeZone: "America/Guatemala"})}</div> 
                <span> email </span> <div className="app-card2__subtext">{user && user.email} </div></div>
                


                <div className="app-card2">{isPro || estatus==="SUSPENDED" ? <>Puedes cancelar cuando quieras</>:<>Suscribete aquí</>}
                  {isPro || estatus==="SUSPENDED" ?  <CancelSubscription setispro={setisPro} />: <Month setispro={setisPro} />}
                  </div>
                <div className="app-card2">
                  <span> Estado de la suscripcion </span> <div className="app-card2__subtext" style={{color: colordeestado }}>{estatus}</div>
                  {suscriptionId && <><span> Suscripcion ID </span> <div className="app-card2__subtext">{suscriptionId}</div></>}
                  
                  </div>
                {estatus ==="SUSPENDED" && <div className="app-card2"><span> Suscripcion suspendida </span> <div className="app-card2__subtext">Tu suscripcion ha sido suspendida, debido a algún problema de pago o con la tarjeta de crédito, cuando este se resuelva tu cuenta se activará automaticamente, tambien puedes cancelar cuando quieras </div></div>}
                
                
                
                </div>
                <div className="content-section">
                  <div className="content-section-title"> Reportar un Problema </div>
                 
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="app-card2">

                  <FeedbackForm />
                  
                  </div>
                  
                  </div>
                  <p> Si la página da errores o no carga correctamene puede que tu sesion haya expirado, reinicia la pagina o inicia sesión de nuevo</p>
                  </div>
                </div>
                
      
                

    </div>
  )
}

export default Account
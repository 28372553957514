import React from 'react';

const ShareToWhatsApp = ({ text }) => {
    
    //the text comes with br tags from html and we need thetext for whatsapp without them
    const pageLink = "https://www.temisgpt.io";
    const linkIntroduction = "Desbloquea el mundo del derecho con Temis:";
    text = text.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n').replace(/<\/div\s*\/?>/gi, '\n').replace(/&nbsp;/g, ' ')+ `\n\n${linkIntroduction} ${pageLink}`;
    const encodedText = encodeURIComponent(text);
    const whatsAppURL = `https://wa.me/?text=${encodedText}`;
    //console.log("Text to share:", text);

    const openWhatsApp = () => {
        if (text === '__:\n\n' || text === null || text === undefined) {
            console.error('No text to share');
            
        }
        else
        {
           
        window.open(whatsAppURL, '_blank', 'noopener,noreferrer');
        }
    }

    return (
        
            <button className='content-button status-button open' onClick={openWhatsApp}>
                Compartir via WhatsApp
            </button>
        
    );
};

export default ShareToWhatsApp;

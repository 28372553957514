import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; 

const NewTableComponent = ({ title, data, isShown, updateShownState, isAnyTableShown }) => {
  const tableRef = useRef();
  const [meanScore, setMeanScore] = useState(0);

  useEffect(() => {
    if (isShown) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isShown]);

  useEffect(() => {
    if (data && data.length > 0) {
      const scoresToConsider = data.slice(0, 1);
      const totalScore = scoresToConsider.reduce((sum, item) => sum + parseFloat(item.score), 0);
      const divisor = scoresToConsider.length;

      const mean = totalScore / divisor;
      setMeanScore(mean.toFixed(2));
    }
  }, [data]);   

  const handleToggle = () => {
    updateShownState(!isShown);
  };

  return (
    data && data.length > 0 &&
    <>
      <h2 onClick={handleToggle} style={{ width: '100%', marginBottom: '10px' }}>
        <button 
          className={`content-button status-button ${isShown ? 'closed' : 'open'}`}
          style={{
            fontSize: '19px',
            transition: 'filter 0.3s ease',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px',
            boxSizing: 'border-box',
            minHeight: '60px',
            height: 'auto',
            whiteSpace: 'normal',
            textAlign: 'left',
            overflow: 'hidden',
            color: 'white'
          }}
        >
          <span style={{ 
            flex: '1',
            marginRight: '10px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            hyphens: 'auto',
          }}>
            {title}
          </span>
          <span style={{ 
            whiteSpace: 'nowrap', 
            fontSize: '0.8em',
            marginLeft: 'auto',
            paddingLeft: '10px',
            flexShrink: 0,
          }}>
            {meanScore}% {isShown ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </button>
      </h2>
      {isShown && (
        <div ref={tableRef}>
          <table>
            <thead>
              <tr>
                <th>Content</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.content}</td>
                  <td>{parseFloat(item.score).toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default NewTableComponent;
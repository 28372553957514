// src/components/ProComparisonTable.js
import React from 'react';

const ProComparisonTable = () => {
  return (
    <div className="comparison-table">
      <table>
        <thead>
          <tr>
            <th>Características</th>
            <th>Plan Estándar</th>
            <th>Plan Pro</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Inteligencia y Precisión</td>
            <td style={{ color: '#999ba5' }}>Básica</td>
            <td style={{ color: 'rgba(0, 255, 0)', fontWeight: 'bold' }}>Avanzada</td>
          </tr>
          <tr>
            <td>Contexto de Búsqueda</td>
            <td style={{ color: '#999ba5' }}>Limitado</td>
            <td style={{ color: 'rgba(0, 255, 0)', fontWeight: 'bold' }}>Hasta 6 veces más amplio</td>
          </tr>
          <tr>
            <td>Análisis de Artículos</td>
            <td style={{ color: '#999ba5' }}>Limitado</td>
            <td style={{ color: 'rgba(0, 255, 0)', fontWeight: 'bold' }}>Hasta 6 veces más artículos</td>
          </tr>
          <tr>
            <td>Acceso al Diario Oficial<br/>(por fechas)</td>
            <td style={{ color: '#999ba5' }}>No incluido</td>
            <td style={{ color: 'rgba(0, 255, 0)', fontWeight: 'bold' }}>Incluido</td>
          </tr>
          <tr>
            <td>Nuevas Funcionalidades</td>
            <td style={{ color: '#999ba5' }}>No incluidas</td>
            <td style={{ color: 'rgba(0, 255, 0)', fontWeight: 'bold' }}>Incluidas</td>
          </tr>
          <tr>
            <td>Servicio al Cliente</td>
            <td style={{ color: '#999ba5' }}>No incluido</td>
            <td style={{ color: 'rgba(0, 255, 0)', fontWeight: 'bold' }}>Incluido</td>
          </tr>
          <tr>
            <td>Precio</td>
            <td style={{ color: '#999ba5' }}>Gratis</td>
            <td style={{ color: 'rgba(0, 255, 0)', fontWeight: 'bold' }}>$8.96 al mes</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProComparisonTable;

import React, { useState } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom'

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { resetPassword } = UserAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            await resetPassword(email);
            setMessage('Check your inbox for further instructions');
        } catch (error) {
            setError('Failed to reset password');
        }
    };

    return (
        <div className="app-card">
            <form onSubmit={handleSubmit}>
                <div className="content-section-title">Reset Password</div>
                {error && <div className="error">{error}</div>}
                {message && <div className="success">{message}</div>}
                <input
                    className='search-bar'
                    type='email'
                    placeholder='Enter your email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className="content-button status-button" type='submit'>Reset Password</button>
            </form>
            <div className="content-section-title">
                <Link to='/signin' className='link-button'>Log In</Link>
            </div>
        </div>
    );
};

export default PasswordReset;

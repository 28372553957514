import React, { useState, useEffect } from 'react';
import { useSidebar } from './layout/context/SidebarContext';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AlertPopup from './AlertPopup';
import NewTableComponent from './NewTable';
import Header from './header';
import ShareToWhatsApp from './share/ShareToWhatsApp';
import DownloadDocxButton from './share/DW';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import MarkupTextCopier from './pop/htmltomkd';

function AccountEngine() {
  // States
  const [useAduanerosLink, setUseAduanerosLink] = useState(false);
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 944);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState('');
  const [respuesta, setRespuesta] = useState('');
  const [shareRespuesta, setShareRespuesta] = useState('');
  const [Titulo, setTitulo] = useState('');
  const [tabledatas, setTableDatas] = useState({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [localNotifications, setLocalNotifications] = useState([]);
  const [isNotificationPopupVisible, setIsNotificationPopupVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  
  //an array of these [] const [isShown, setIsShown] = useState(false);
  const [shownTables, setShownTables] = useState({});

  const { token, fetchNotifications, logout } = UserAuth();
const { setSidebarContent } = useSidebar();
const navigate = useNavigate();

const [documentFilters, setDocumentFilters] = useState(
  {
    Constitucion: false,
    CodigoTrabajo: false,
    CodigoCivil: false,
    CodigoPenal: false,
    LeyAmbiente: false,
    LeyTransito: false,
    //LeyElectoral: false,
    CodigoProcesalPenal: false,
    Codigocomercio: false,
    Codigotributario: false,
    LeydelIgss: false,
    Leycontralavadodedinero: false,
    ReglamentodeLeycontralavadodedinero: false,
    Leydeextinsiondedominio: false,
    reglamendodeleydeextinsiondedominio: false,
    leyorganismojudicial: false,
    leydeserviciocivil: false,
    leycontraelfemicidio: false,
    leycontraviolenciasexual: false,
    codigoprocesalcivil: false,
    codigodenotariado: false,
    leydeamparo: false,
    leydeemisiondelpensamiento: false,
    leydelordenpublico: false,
    leydeejecutivo: false,
    proteccionybieneestaranimal: false,
    tarjetasdecredito: false,
    LeyOrganicaMinisterioPublico: false, // New
    LeyProteccionConsumidor: false,      // New
    LeyIVA: false,                       // New
    LeyEducacionNacional: false,         // New
    LeyAccesoInformacion: false,         // New
    LeyArmasMuniciones: false,           // New
    LeyContratacionesEstado: false,      // New
    LeyRegistroCatastral: false,         // New
    LeyArbitraje: false,                 // New
    LeyImpuestoCirculacion: false,       // New
    LeyProteccionNinezAdolescencia: false, // New
    LeyTimbreForense: false,             // New
    LeyTimbresFiscales: false,           // New
    LeyRegistroSucesorios: false,        // New
    LeyTramitacionNotarial: false,       // New
    ReglamentoTimbresFiscales: false,    // New
    ReglamentoTimbreForense: false       // New
  }
);

const documentLabels = {
    Constitucion: 'Constitucion',
    CodigoTrabajo: 'Codigo de Trabajo',
    CodigoCivil: 'Codigo Civil',
    CodigoPenal: 'Codigo Penal',
    LeyAmbiente: 'Ley de Medio Ambiente',
    LeyTransito: 'Ley de Transito',
    LeyElectoral: 'Ley Electoral y de Partidos Políticos',
    CodigoProcesalPenal: 'Codigo Procesal Penal',
    Codigocomercio: 'Codigo comercio',
    Codigotributario: 'Codigo tributario',
    LeydelIgss: 'Ley Organica del Instituto Guatemalteco de Seguridad Social',
    Leycontralavadodedinero: 'Ley contra el lavado de dinero u otros activos',
    ReglamentodeLeycontralavadodedinero: 'Reglamento de la Ley Contra el Lavado de Dinero u Otros Activos',
    Leydeextinsiondedominio: 'Ley de extincion de dominio',
    reglamendodeleydeextinsiondedominio: 'Reglamento de la Ley de Extincion de Dominio',
    leyorganismojudicial: 'Ley del Organismo Judicial',
    leydeserviciocivil: 'Ley de Servicio Civil',
    leycontraelfemicidio: 'Ley contra el femicidio y otras formas de violencia contra la mujer',
    leycontraviolenciasexual: 'Ley Contra la Violencia Sexual Explotacion y Trata de Personas',
    codigoprocesalcivil: 'Código Procesal Civil y Mercantil',
    codigodenotariado: 'Codigo de Notariado',
    leydeamparo: 'Ley de Amparo, Exhibición Personal y de Constitucionalidad',
    leydeemisiondelpensamiento: 'Ley de Emsión del Pensamiento',
    leydelordenpublico: 'Ley del Orden Público',
    leydeejecutivo: 'Ley Ejecutivo',
    proteccionybieneestaranimal: 'Proteccion y Bienestar Animal',
    tarjetasdecredito: 'Tarjetas de Credito',
    LeyOrganicaMinisterioPublico: 'Ley Organica del Ministerio Publico', // New
    LeyProteccionConsumidor: 'Ley de protección al consumidor y usuario', // New
    LeyIVA: 'Ley del impuesto del valor agregado', // New
    LeyEducacionNacional: 'Ley de Educación Nacional', // New
    LeyAccesoInformacion: 'Ley de Acceso a la Informacion Publica', // New
    LeyArmasMuniciones: 'Ley de Armas y Municiones', // New
    LeyContratacionesEstado: 'Ley de Contrataciones del Estado', // New
    LeyRegistroCatastral: 'Ley de Registro de Informacion Catastral', // New
    LeyArbitraje: 'Ley de Arbitraje', // New
    LeyImpuestoCirculacion: 'Ley del Impuesto sobre Circulación de Vehiculos Terrestres, Maritimos y Aereos', // New
    LeyProteccionNinezAdolescencia: 'Ley de proteccion integral de la ninez y adolescencia', // New
    LeyTimbreForense: 'Ley de Timbre Forense y Notarial', // New
    LeyTimbresFiscales: 'Ley del Impuesto de Timbres Fiscales y de Papel Sellado Especial para Protocolos', // New
    LeyRegistroSucesorios: 'Ley del Registro de Procesos Sucesorios', // New
    LeyTramitacionNotarial: 'Ley Reguladora de la Tramitación Notarial de Asuntos de Jurisdicción Voluntaria', // New
    ReglamentoTimbresFiscales: 'Reglamento de la Ley del Impuesto de Timbres Fiscales y de Papel Sellado Especial para Protocolos', // New
    ReglamentoTimbreForense: 'Reglamento de la Ley del Timbre Forense y Timbre Notarial', // New
    testeo: 'Testeo'
};


  function markdownToWhatsApp(text) {
    let whatsappFormattedText = text;
    whatsappFormattedText = whatsappFormattedText.replace(/(\*\*|__)(.*?)\1/g, '*$2*');
    whatsappFormattedText = whatsappFormattedText.replace(/(\*|_)(.*?)\1/g, '_$2_');
    whatsappFormattedText = whatsappFormattedText.replace(/~~(.*?)~~/g, '~$1~');
    whatsappFormattedText = whatsappFormattedText.replace(/^(\s*)#+([^\n]*)/gm, '$1*$2*');
    whatsappFormattedText = whatsappFormattedText.replace(/(\[)(.*?)(\])/g, '_$2_');
    whatsappFormattedText = whatsappFormattedText.replace(/\* /g, '*');
    whatsappFormattedText = whatsappFormattedText.replace(/ \*\n/g, '*\n');
    whatsappFormattedText = whatsappFormattedText.replace(/_ /g, '_');
    whatsappFormattedText = whatsappFormattedText.replace(/ _\n/g, '_\n');
    return whatsappFormattedText;
  }

  const handleFilterToggle = () => {
    setIsFilterVisible(!isFilterVisible);
    if (isFilterVisible) {
      setIsFilterActive(false);
      setDocumentFilters(Object.keys(documentFilters).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}));
    }
  };

  const handleCheckboxChange = (event) => {
    const newFilters = {
      ...documentFilters,
      [event.target.name]: event.target.checked,
    };
    setDocumentFilters(newFilters);

    const isActive = Object.values(newFilters).includes(true);
    setIsFilterActive(isActive);
  };

  const countChecked = () => {
    return Object.values(documentFilters).filter(val => val).length;
  };

  const execute_new = async (e) => {
    setTableDatas({});
    e.preventDefault();

    const checkedItems = Object.entries(documentFilters).filter(([key, value]) => value).map(([key]) => key);
    let Items = [];
    if (isFilterActive && checkedItems.length > 0) {
      Items = checkedItems.map(key => documentLabels[key]);
    }
    //console.log(Items);

    setLoading(true);
    setRespuesta("");
    setShareRespuesta("");

    const baseURL = useAduanerosLink ? `${process.env.REACT_APP_API_BASE_URL_ADUANAS}` : `${process.env.REACT_APP_API_BASE_URL}`;

    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const response = await fetch(`${baseURL}/api/execute`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ input: inputText, documents: Items }),
        signal: signal
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let chunks = '';
      let jsonBuffer = '';
      let isJson = false;

      reader.read().then(function processText({ done, value }) {
        if (done) {
          controller.abort();
          return;
        }

        let incoming_chunk = decoder.decode(value);

        if (incoming_chunk.includes("--JSON-START--")) {
          if (incoming_chunk.includes("--JSON-END--")) {
            const parts = incoming_chunk.split("--JSON-START--");
            const textPart = parts[0];
            const jsonPart = parts[1].split("--JSON-END--")[0];
            chunks += textPart;
            jsonBuffer += jsonPart;

            const sanitizedHTML = DOMPurify.sanitize(marked.parse(chunks));
            setRespuesta(sanitizedHTML);
            setShareRespuesta(chunks);

            try {
              const jsonData = JSON.parse(jsonBuffer);
              setTableDatas(jsonData);
            } catch (error) {
              console.error("Failed to parse JSON part:", error);
            }

            controller.abort();
            return;
          } else {
            const parts = incoming_chunk.split("--JSON-START--");
            const textPart = parts[0];
            jsonBuffer = parts[1]; // Start buffering JSON part

            chunks += textPart;
            const sanitizedHTML = DOMPurify.sanitize(marked.parse(chunks));
            setRespuesta(sanitizedHTML);
            setShareRespuesta(chunks);

            isJson = true;  // Mark as JSON part starts
          }
        } else if (incoming_chunk.includes("--JSON-END--")) {
          const parts = incoming_chunk.split("--JSON-END--");
          jsonBuffer += parts[0]; // Append the remaining JSON part

          try {
            const jsonData = JSON.parse(jsonBuffer);
            setTableDatas(jsonData);
          } catch (error) {
            console.error("Failed to parse JSON part:", error);
          }

          controller.abort();
          return;
        } else {
          if (isJson) {
            jsonBuffer += incoming_chunk; // Continue buffering JSON part
          } else {
            chunks += incoming_chunk;
            const sanitizedHTML = DOMPurify.sanitize(marked.parse(chunks));
            setRespuesta(sanitizedHTML);
            setShareRespuesta(chunks);
          }
        }

        return reader.read().then(processText);
      }).then(async () => {
        setLoading(false);
        pickRandomAnimation();
      });
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 403) {
        setRespuesta("Tu sesión expiró, por favor recarga la página o inicia sesión de nuevo.");
      } else if (error.response && error.response.status === 429) {
        setRespuesta("Tu sesión expiró, por favor recarga la página o inicia sesión de nuevo.");
      } else if (error.response && error.response.status === 400) {
        setRespuesta("Ingrese un texto adecuado o su consulta es demasiado larga.");
      } else {
        setRespuesta("Tu sesión expiró, por favor recarga la página o inicia sesión de nuevo.");
      }
    }
  };


  const manuelfilro = () => navigate('/manual#Filtros');

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 900);

    const handleClickOutside = (event) => {
      if (isMobileView && isSideMenuVisible && !document.querySelector('.left-side').contains(event.target) && !document.querySelector('.hamburger-menu').contains(event.target)) {
        setIsSideMenuVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSideMenuVisible]);

  useEffect(() => {
    const getNotifications = async () => {
      const fetchedNotifications = await fetchNotifications();
      setLocalNotifications(fetchedNotifications);
    };

    getNotifications();
  }, []);



  const sortedTableDatas = Object.entries(tabledatas).sort(([, dataA], [, dataB]) => parseFloat(dataB[0].score) - parseFloat(dataA[0].score)).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    //constantly monitor sortedTableDatas and add a new const [isShown, setIsShown] = useState(false);  to isanyShown
    const updateTableShownState = (title, isShown) => {
      setShownTables(prev => ({...prev, [title]: isShown}));
    };
    const isAnyTableShown = Object.values(shownTables).some(value => value);

  const animations = [
    (<div className="loader"></div>),
    (<div aria-label="Orange and tan hamster running in a metal wheel" role="img" className="wheel-and-hamster">
      <div className="wheel"></div>
      <div className="hamster">
        <div className="hamster__body">
          <div className="hamster__head">
            <div className="hamster__ear"></div>
            <div className="hamster__eye"></div>
            <div className="hamster__nose"></div>
          </div>
          <div className="hamster__limb hamster__limb--fr"></div>
          <div className="hamster__limb hamster__limb--fl"></div>
          <div className="hamster__limb hamster__limb--br"></div>
          <div className="hamster__limb hamster__limb--bl"></div>
          <div className="hamster__tail"></div>
        </div>
      </div>
      <div className="spoke"></div>
    </div>),
    (<div className="loader2"></div>)
  ];

  const pickRandomAnimation = () => {
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
    setCurrentAnimation(randomAnimation);
  };
  const [currentAnimation, setCurrentAnimation] = useState(animations[0]); // Start with the first animation
  useEffect(() => {
    setSidebarContent(
      <>
        <h3 className='title'>Filtro de<br/> Leyes</h3>
        <label className="switch">
          <input type="checkbox" checked={isFilterVisible} onChange={handleFilterToggle} />
          <span className="slider round"></span>
        </label>
        <br/>
        {isFilterVisible && (
          <div className="side-wrapper">
            {Object.entries(documentFilters).map(([key, value]) => (
              <div className="checkbox-wrapper" key={key}>
                <input
                  type="checkbox"
                  className="checkbox"
                  id={key}
                  name={key}
                  checked={value}
                  onChange={handleCheckboxChange}
                />
                <label className="labelito" htmlFor={key}>{documentLabels[key]}</label>
              </div>
            ))}
          </div>
        )}
      </>
    );

    return () => setSidebarContent(null); // Clear sidebar content on unmount
  }, [isFilterVisible, documentFilters]);

  return (
    <div className='content-section'>
      {isNotificationPopupVisible && (
        <AlertPopup
          title={localNotifications[0].titulo}
          message={localNotifications[0].texto}
          onClose={() => setIsNotificationPopupVisible(false)}
        />
      )}
      {isAlertVisible && (
        <AlertPopup
          title="Alerta"
          message="POR FAVOR, SELECCIONA AL MENOS UNA LEY O DESACTIVA EL FILTRO DE LEYES"
          onClose={() => setIsAlertVisible(false)}
        />
      )}
      <form onSubmit={execute_new}>
        <Header isFilterVisible={isFilterVisible} countChecked={countChecked} manuelfilro={manuelfilro} />
        <div className="tooltip">
          <div className="checkbox-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <input
              type="checkbox"
              className="checkbox"
              id="regimen aduanero y SAT"
              name="regimen aduanero y SAT"
              checked={useAduanerosLink}
              onChange={() => setUseAduanerosLink(!useAduanerosLink)}
            />
            <label className="labelito glassmorphism" htmlFor="regimen aduanero y SAT">
              Si tu pregunta tiene que ver con Aduanas o SAT activa este boton
            </label>
          </div>
        </div>
        <textarea
          type="text"
          placeholder="Search"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          required
        />
        <button className="content-button status-button" type="submit" disabled={loading || (isSideMenuVisible && isMobileView)}>Generar</button>

      </form>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {currentAnimation}
        </div>
      )}
      {respuesta && (
        <div>
          <div id="parrafo">
            <p dangerouslySetInnerHTML={{ __html: respuesta }}></p>
          </div>
          <MarkupTextCopier originalMarkup={shareRespuesta} />
          <div className="content-section-title">
            Complementa tu consulta con los siguientes Artículos:
          </div>
          <div id="tablas">
            {Object.entries(sortedTableDatas).map(([title, data], index) => (
              <NewTableComponent 
              key={title} 
              title={title} 
              data={data} 
              isShown={shownTables[title] || false}
              updateShownState={(isShown) => updateTableShownState(title, isShown)}
              isAnyTableShown={isAnyTableShown}
            />
            ))}
          </div>
        </div>
      )}
      <div className='content-section'></div>
      <div className='content-section'></div>
      <div className="content-wrapper-header2">
        <div className='content-section'>comparte tu respuesta</div>
        <div className='content-section'></div>
        <ShareToWhatsApp text={markdownToWhatsApp("*" + inputText + "*:" + "\n\n" + shareRespuesta)} />
        <DownloadDocxButton input={respuesta} titulo={Titulo} />
        <div className='content-section'></div>
      </div>
    </div>
  );
  
}

export default AccountEngine;

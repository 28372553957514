import React from 'react';
import { asBlob } from 'html-docx-js-typescript';

const DownloadDocxButton = ({ input, titulo }) => {
  const downloadDocx = () => {
    console.log(titulo);
    // Convert HTML string to Blob (DOCX)
    const htmlContent = `<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>${input}</body></html>`;
    console.log(htmlContent);
    asBlob(htmlContent).then(blob => {
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger download
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = titulo +'.docx';
      document.body.appendChild(anchor);
      anchor.click();

      // Clean up
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    });
  };

  const isMobileDevice = () => {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
  };
  const mobileDeviceMessage = "Descargar Word solamente funciona en PC debido al formato del documento generado";
  

  return (
    <>
    <button
      className='content-button status-button open'
      onClick={downloadDocx}
      title="Esta funcionalidad solamente funciona en PC debido al formato del documento de word generado"
    >
      Descargar como documento Word
    </button>
    {isMobileDevice() && <p className="content-section-disclaimer">{mobileDeviceMessage}</p>}
    </>
  );
};

export default DownloadDocxButton;
